define("discourse/plugins/discourse-ip-location/discourse/templates/connectors/poster-name__after/ip-location-connector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <p>Not a plugin outlet, so not shown</p>
  </div>
  
  */
  {
    "id": "7PQCshbB",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,2],[12],[1,\"Not a plugin outlet, so not shown\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"p\"]]",
    "moduleName": "discourse/plugins/discourse-ip-location/discourse/templates/connectors/poster-name__after/ip-location-connector.hbs",
    "isStrictMode": false
  });
});